export type Blog = {
    id: number;
    image: string;
    title: string;
    slug: string;
    description: string;
    author: string | null;
    author_feedback: string;
    author_designation: string | null;
    authour_image: string | null; 
    author_profile_url: string | null;
    created: string;
};

export type AddComment = {
    user_details: string;
}

export type BlogComment = {
    id: number;
    blog_post: string;
    user: string;
    content: string;
    created_at: string;
    approved: boolean;
    admin_reply: string | null;
    admin_profile_url: string | null;
}


export type BlogListState = {
    blogs: Blog[] | undefined;
    success?: boolean;
    error: string | null;
    loading: boolean;
};

export const BlogListInitialState: BlogListState = {
    blogs: undefined,
    error: null,
    loading: false,
};


export type BlogDetailState = {
    blog: Blog | undefined;
    success?: boolean;
    error: string | null;
    loading: boolean;
};

export type AddCommentState = {
    user_details: AddComment | undefined;
    success?: boolean;
    error: string | null;
    loading: boolean;
}

export type  UserBlogCommentState = {
    comments: BlogComment[] | undefined;
    success?: boolean;
    error: string | null;
    loading: boolean;
}

export const UserBlogCommentInitialState: UserBlogCommentState = {
    comments: undefined,
    error: null,
    loading: false,
}

export const AddCommentInitialState: AddCommentState = {
    user_details: undefined,
    error: null,
    loading: false,
}


export const BlogDetailInitialState: BlogDetailState = {
    blog: undefined,
    error: null,
    loading: false,
};