import React from "react";
import "./footer.scss";
import { Link } from "react-router-dom";
export default function Footer({ locationUser }: any) {
  return (
    <footer>
      <div>
        {/* <div className="follow-us mt-4 pt-5">
          <h1>
            <span>Follow Us</span> On:
          </h1>
          <div className="icon-conatiner">
            <a
              className="icons"
              // href={miscData?.facebook}
              href="https://www.facebook.com/buybackartindia/"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              {" "}
              <i className="fa-brands fa-facebook fa-2x mx-1"></i>
            </a>
            <a
              className="icons"
              style={{ textDecoration: "none" }}
              // href={miscData?.instagram}
              href="https://www.instagram.com/buybackart/?hl=en"
              target="_blank"
            >
              {" "}
              <i className="fa-brands fa-instagram fa-2x mx-1"></i>
            </a>
            <a
              className="icons"
              style={{ textDecoration: "none" }}
              // href={miscData?.youtube}
              href="https://www.youtube.com/channel/UCHkKUA0iSKjqDhq9hr8AlCw"
              target="_blank"
            >
              {" "}
              <i className="fa-brands fa-youtube fa-2x mx-1"></i>
            </a>
            <a
              className="icons"
              style={{ textDecoration: "none" }}
              // href={miscData?.linkedIn}
              href="https://in.linkedin.com/company/buybackartnow"
              target="_blank"
            >
              {" "}
              <i className="fa-brands fa-linkedin fa-2x  mx-1"></i>
            </a>
            <a
              className="icons"
              style={{ textDecoration: "none" }}
              // href={miscData?.twitter}
              href="https://mobile.twitter.com/BuyBacknow/status/1321062028182614023"
              target="_blank"
            >
              {" "}
              <i className="fa-brands fa-twitter fa-2x mx-1"></i>
            </a>
          </div>
        </div> */}

        <div className="footer">
          <div className="container footer-container">
            <div className="footer-left-col">
              <div className="footer-col">
                <h6>Services</h6>
                <Link className="link" to="/sell-phones">
                  <p>Sell Phone</p>
                </Link>
                <Link className="link" to="/sell-laptops">
                  <p>Sell Laptops</p>
                </Link>
                <Link className="link" to="/sell-tablets">
                  <p>Sell Tablets</p>
                </Link>
                <Link className="link" to="/sell-watches">
                  <p>Sell Watches</p>
                </Link>
              </div>
              <div className="footer-col">
                <h6>More Info</h6>
                <Link className="link" to="/about">
                  <p>About Us</p>
                </Link>

                <Link className="link" to="/contact-us">
                  <p>Contact Us</p>
                </Link>
                <Link className="link" to="/refund-and-cancellation">
                  <p>Refund and Cancellation</p>
                </Link>

                <Link className="link" to="/privacy-policy">
                  <p>Privacy Policy</p>
                </Link>

                <Link className="link" to="/terms-and-condition">
                  <p>Terms and Condition</p>
                </Link>

                {/* <p>
              Address : DLF Prime Tower 612, 6th Floor Okhla Phase 1 New Delhi -
              110020
            </p> */}
              </div>
              <div className="footer-col">
                <h6>Contact Us</h6>
                <p>
                  {" "}
                  Enquiry:{" "}
                  <a href="mailto:info@buybackart.com">info@buybackart.com</a>
                </p>
                <p>
                  {" "}
                  Support:{" "}
                  <a href="mailto:support@buybackart.com">
                    support@buybackart.com
                  </a>
                </p>
                <p>
                  {" "}
                  Call Us At:{" "}
                  {locationUser && locationUser.number
                    ? `+91 ${locationUser.number}`
                    : "+91 9310353308"}{" "}
                </p>
                <p className="address">
                  Address : DLF Prime Tower 115, 1st Floor Okhla Phase 1 New
                  Delhi - 110020
                </p>
              </div>
            </div>
            <div className="footer-right-col">
              <div className="footer-col">
                <h6>Follow Us</h6>

                <div className="icon-conatiner">
                  <a
                    className="icons"
                    // href={miscData?.facebook}
                    href="https://www.facebook.com/buybackartindia/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    <i className="fa-brands fa-facebook fa-2x mx-1" />
                  </a>
                  <a
                    className="icons"
                    style={{ textDecoration: "none" }}
                    // href={miscData?.instagram}
                    href="https://www.instagram.com/buybackart/?hl=en"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <i className="fa-brands fa-instagram fa-2x mx-1" />
                  </a>
                  <a
                    className="icons"
                    style={{ textDecoration: "none" }}
                    // href={miscData?.youtube}
                    href="https://www.youtube.com/channel/UCHkKUA0iSKjqDhq9hr8AlCw"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <i className="fa-brands fa-youtube fa-2x mx-1" />
                  </a>
                  <a
                    className="icons"
                    style={{ textDecoration: "none" }}
                    // href={miscData?.linkedIn}
                    href="https://in.linkedin.com/company/buybackartnow"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <i className="fa-brands fa-linkedin fa-2x  mx-1" />
                  </a>
                  <a
                    className="icons"
                    style={{ textDecoration: "none" }}
                    // href={miscData?.twitter}
                    href="https://mobile.twitter.com/BuyBacknow/status/1321062028182614023"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <i className="fa-brands fa-twitter fa-2x mx-1" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="policy-container container-fluid px-5">
          <span className="policy">
            **All products names, logos, and brands mentioned on this website
            are the sole property of their respective owners. The use of these
            names, logos, and brands is purely for identification purposes. It
            is important to note that the mention of these names, logos, and
            brands does not in any way imply endorsement or affiliation with
            this website.
          </span>
        </div>
        <div className="copyright">
          <p
            className="footer-copyright-text mb-0 fw-bold"
            style={{ fontSize: "12px" }}
          >
            <a className="footer-copyright-link" href="#" target="_self">
              Copyright © 2023 Buybackart.com | All rights reserved
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}
