import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BlogListState, BlogListInitialState, BlogDetailState, BlogDetailInitialState, AddCommentState, AddCommentInitialState, UserBlogCommentInitialState, UserBlogCommentState } from "../state/blogState";


const blogSlice = createSlice({
    name: "blog",
    initialState: BlogListInitialState,
    reducers: {
        blogRequest: (state) => {
            state.loading = true;
            state.blogs = undefined;
        },
        blogSuccess: (
            state,
            action: PayloadAction<BlogListState>
        ) => {
            state.loading = false;
            state.success = true;
            state.blogs = action.payload.blogs;
        },
        blogFailure: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
            state.success = false;
        },
    },
});

const blogDetailSlice = createSlice({
    name: "blogDetail",
    initialState: BlogDetailInitialState,
    reducers: {
        blogDetailRequest: (state) => {
            state.loading = true;
            state.blog = undefined;
        },
        blogDetailSuccess: (
            state,
            action: PayloadAction<BlogDetailState>
        ) => {
            state.loading = false;
            state.success = true;
            state.blog = action.payload.blog;
        },
        blogDetailFailure: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
            state.success = false;
        },
    },
});

const userBlogCommentSlice = createSlice({
    name: "userBlogComment",
    initialState: UserBlogCommentInitialState,
    reducers: {
        blogCommentRequest: (state) => {
            state.loading = true;
            state.comments = undefined;
        },
        blogCommentSuccess: (
            state,
            action: PayloadAction<UserBlogCommentState>
        ) => {
            state.loading = false;
            state.success = true;
            state.comments = action.payload.comments;
        },
        blogCommentFailure: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
            state.success = false;
        },
    },
});

const addCommentSlice = createSlice({
    name: "addComment",
    initialState: AddCommentInitialState,
    reducers: {
        addCommentRequest: (state) => {
            state.loading = true;
            state.user_details = undefined;
        },
        addCommentSuccess: (
            state,
            action: PayloadAction<AddCommentState>
        ) => {
            state.loading = false;
            state.success = true;
            state.user_details = action.payload.user_details;
        },
        addCommentFailure: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
            state.success = false;
        },

        addCommentReset: (state) => {
            state.loading = false;
            state.success = false;
            state.user_details = undefined;
          },
    },
});

export const {blogDetailFailure, blogDetailRequest, blogDetailSuccess} = blogDetailSlice.actions
export const BlogDetailReducer = blogDetailSlice.reducer;

export const { blogRequest, blogSuccess, blogFailure } =
    blogSlice.actions;
export const BlogReducer = blogSlice.reducer;

export const { addCommentRequest, addCommentSuccess, addCommentFailure, addCommentReset } =
    addCommentSlice.actions;
export const AddCommentReducer = addCommentSlice.reducer;

export const { blogCommentRequest, blogCommentFailure, blogCommentSuccess } = userBlogCommentSlice.actions
export const UserBlogCommentReducer = userBlogCommentSlice.reducer;

